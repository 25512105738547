<template>
    <div style="background-color: white;padding: 30px">
        <div class="title">1.上传你想要导入的数据</div>
        <div style="display: flex;">
            <div style="width: 350px;padding: 30px 0px 50px 0px">
                <a-upload-dragger
                                  name="file"
                                  :multiple="true"
                                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                  @change="handleChange"
                >
                    <p class="ant-upload-drag-icon">
                        <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                        将文件拖到此处或点击上传
                    </p>
                    <p class="ant-upload-hint">
                        不能修改模板中的表头，文件大小不能超过10M
                    </p>
                </a-upload-dragger>
            </div>

            <div style="display: flex;padding: 20px 0px 40px 20px;flex-direction: column;justify-content: center;">
                <div style="display: flex;padding: 50px;flex-direction: column;justify-content: center;border: 1px solid rgb(232, 232, 232);">
                    <a-icon type="file-text" theme="filled" :style="{ fontSize: '30px', color: '#08c' }"/>
                    <div>下载模板</div>
                </div>

            </div>
        </div>
        <div class="title">2.选择名单来源</div>
        <div class="content">
            <div class="form">
                <div style="display: flex;align-items: flex-start;width: 60px">来源</div>
                <div>
                    <a-select v-model="region" placeholder="请选择" style="width: 200px">
                    <a-select-option value="shanghai">
                        待处理
                    </a-select-option>
                    <a-select-option value="beijing">
                        已处理
                    </a-select-option>
                </a-select>
                </div>
            </div>
            <div class="form">
                <div style="display: flex;align-items: flex-start;width: 60px">指定活动</div>
                <a-select v-model="region" placeholder="请选择" style="width: 200px">
                    <a-select-option value="shanghai">
                        待处理
                    </a-select-option>
                    <a-select-option value="beijing">
                        已处理
                    </a-select-option>
                </a-select>
            </div>

        </div>
        <div class="title">3.选择客户负责人，分配客户</div>
        <div class="content">


            <div class="form">
            <div style="display: flex;align-items: flex-start;width: 60px">负责人</div>
            <a-select v-model="region" placeholder="请选择" style="width: 200px">
                <a-select-option value="shanghai">
                    待处理
                </a-select-option>
                <a-select-option value="beijing">
                    已处理
                </a-select-option>
            </a-select>
            </div>
<!--        <div class="form">-->
<!--            <a-radio v-for="item in 2" style="padding: 10px;">我负责的全部客户</a-radio>-->
<!--        </div>-->
        <div class="form">
            <div style="display: flex;align-items: flex-start;width: 60px">任务</div>
            <a-select v-model="region" placeholder="请选择" style="width: 200px">
                <a-select-option value="shanghai">
                    待处理
                </a-select-option>
                <a-select-option value="beijing">
                    已处理
                </a-select-option>
            </a-select>
        </div>


        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },

    methods: {

    },
}
</script>

<style scoped>
.title{
    font-size: 20px;
}
    .content{
        padding: 10px 0px 40px 10px;
    }
    .form{
        padding: 20px 0px 10px 0px;
        display: flex;
    }
</style>